/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import '../pages/blog/blog.css'
import Pagina from '../components/Pagina'
class PaisIndex extends React.Component {
  render () {
    let cat = ''
    const { data } = this.props
    const posts = data.allContentfulBlog.edges
    const outros = data.outros.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    // console.log(cat)
    return (
      <Fragment>
        <Pagina pagina={data.pagina} />
        <div id="banner-pais-e-filhos">
          <div className="texto">
            <span style={{
              color: 'var(--quaternary-color)',
              lineHeight: 1,
              fontSize: '33px',
              fontWeight: 300
            }}>PAIS, MÃES &</span>
            <br />
            <span style={{
              color: 'var(--quaternary-color)',
              fontSize: '33px',
              fontWeight: 600
            }}>FILHOS COM AMOR</span>
          </div>
        </div>
        <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
          <h2 className="d-flex flex-column flex-sm-row mt-5 mb-3 theme-secundary">CATEGORIA DE CONTÉUDOS -<span className="theme-primary ml-sm-2">PAIS E FILHOS</span></h2>
        </div>
        <section className="container">
          <p></p>
          <div className="row row-cols-1 row-cols-md-3">
            {
              posts.map((post, index) => {
                return (
                  <div key={index} className="col d-flex align-items-stretch">
                    <div className="card mb-4 box-shadow">
                      <GatsbyImage
                        image={post.node.image.localFile.childImageSharp.gatsbyImageData}
                        className="card-img-top"
                        alt={post.node.slug}
                        style={{ height: '200px', fontSize: '18px' }} />
                      <div className="card-body">
                        <h3 className="card-title" style={{ color: 'red' }}>{post.node.title}</h3>
                        <p className="card-text text-secundary" style={{ color: 'gray', fontSize: '14px' }}>{post.node.subtitle}</p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <Link to={`/blog/pais-e-filhos/${post.node.slug}/`} className="btn btn-block btn-lg orange" >Leia Mais</Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                listStyle: 'none',
                padding: 0,
                paddingRight: '25vw',
                paddingLeft: '25vw',
                paddingBottom: '1vw'
              }}
            >
              {!isFirst && (
                <Link to={`/blog/pais-e-filhos/${prevPage}/`} rel="prev" style={{ color: '#1A3797' }}>
                  «
                </Link>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <li
                  key={`pagination-number${i + 1}`}
                  style={{
                    margin: 0
                  }}
                >
                  <Link
                    to={`/blog/pais-e-filhos/${i === 0 ? '' : `${i + 1}`}/`}
                    style={{
                      padding: 5,
                      textDecoration: 'none',
                      color: i + 1 === currentPage ? '#ffffff' : '#1A3797',
                      background: i + 1 === currentPage ? '#1A3797' : ''
                    }}
                  >
                    {i + 1}
                  </Link>
                </li>
              ))}
              {!isLast && (
                <Link to={`/blog/pais-e-filhos/${nextPage}/`} rel="next" style={{ color: '#1A3797' }}>
                  »
                </Link>
              )}
            </ul>
          </div>
          <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
            <h2 className="d-flex flex-column flex-sm-row mt-5 mb-3 theme-secundary">NOSSAS<span className="theme-primary ml-sm-2">OFERTAS</span></h2>
          </div>
          <Link to={'/ofertas/'}>
            <GatsbyImage
              image={data.imgOfertas.childImageSharp.gatsbyImageData}
              alt="ofertas"
              style={{ width: '100%', paddingBottom: '30px' }} />
          </Link>
          <div className="col d-lg-flex flex-lg-column align-items-lg-center text-lg-center p-md-0">
            <h2 className="d-flex flex-column flex-sm-row mt-5 mb-3 theme-secundary">MAIS<span className="theme-primary ml-sm-2">NOTICIAS</span></h2>
          </div>
          <div className="row row-cols-1 row-cols-md-3">
            {
              outros.map((post, index) => {
                if (post.node.categoria[0] === 'SÊNIOR') {
                  cat = 'senior'
                }
                if (post.node.categoria[0] === 'MUNDO DA BELEZA') {
                  cat = 'beleza'
                }
                if (post.node.categoria[0] === 'PAIS E FILHOS') {
                  cat = 'pais-e-filhos'
                }
                return (
                  <div key={index} className="col d-flex align-items-stretch">
                    <div className="card mb-4 box-shadow">
                      <GatsbyImage
                        image={post.node.image.localFile.childImageSharp.gatsbyImageData}
                        className="card-img-top"
                        alt={post.node.slug}
                        style={{ height: '200px', fontSize: '18px' }} />
                      <div className="card-body">
                        <h3 className="card-title" style={{ color: 'red' }}>{post.node.title}</h3>
                        <p className="card-text text-secundary" style={{ color: 'gray', fontSize: '14px' }}>{post.node.subtitle}</p>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <Link to={`/blog/${cat}/${post.node.slug}/`} className="btn btn-block btn-lg orange" >Leia Mais</Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <Link to="/blog/" title="Leia mais postagens do nosso blog" className="d-flex justify-content-center align-items-center mt-sm-4 btn theme-border-2 theme--primary text-white w-100 mb-3">VER MAIS</Link>
        </section>
      </Fragment>
    )
  }
}

export default PaisIndex
export const pageQuery = graphql`query blogPaisQuery($skip: Int!, $limit: Int!) {
  allContentfulBlog(
    filter: {categoria: {eq: "PAIS E FILHOS"}}
    sort: {fields: [date], order: DESC}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        title
        subtitle
        slug
        categoria
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  imgOfertas: file(
    relativePath: {eq: "images/desktop/Banner_Drogaleste_Ofertas_PaisFilhos.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  pagina: contentfulPagina(path: {eq: "/blog/pais-e-filhos/"}) {
    path
    metaTitle
    metaDescription
    tags
  }
  outros: allContentfulBlog(limit: 9) {
    edges {
      node {
        title
        subtitle
        slug
        categoria
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
